
import {Component, Mixins, Prop} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {TakipSorguAlanlani} from "@/plugins/uyap-plugin/store/modules/TaskDataInterface/TakipSorgulaTaskDataInterface";

@Component({
  components: {}
})
export default class TakipSorguAlanlariPicker extends Mixins(SimpleInputMixin) {
  @Prop() rules!: string;

  takipSorguAlanlari = TakipSorguAlanlari;

  get icon() {
    if (this.localValue.length == this.takipSorguAlanlari.length)
      return 'mdi-close-box';
    if (this.localValue.length > 0)
      return 'mdi-minus-box';
    return 'mdi-checkbox-blank-outline';
  }

  tumunuSec() {
    this.$nextTick(() => {
      if (this.localValue.length == this.takipSorguAlanlari.length) {
        this.localValue = []
      } else {
        this.localValue = this.takipSorguAlanlari;
      }
      this.input();
    })
  }

  remove(item: any){
    this.localValue.splice(this.localValue.findIndex((v:any) => (v.name == item.name)), 1)
  }
}

export const TakipSorguAlanlari: Array<TakipSorguAlanlani> = [
  {
    "type": "ucretsizSorgu",
    "name": "sgkssk",
    "value": "1",
    "borcluyaOzel": true,
    "label": "SGK SSK"
  },
  {
    "type": "ucretsizSorgu",
    "name": "dosyaborclulari",
    "value": "1",
    "borcluyaOzel": true,
    "label": "Dosya Borçluları"
  },
  {
    "type": "ucretsizSorgu",
    "name": "taraflar",
    "value": "1",
    "borcluyaOzel": false,
    "label": "Dosya Tarafları"
  },
  {
    "type": "ucretliSorgu",
    "name": "egm",
    "value": "1",
    "borcluyaOzel": true,
    "label": "EGM"
  },
  {
    "type": "ucretliSorgu",
    "name": "egmmahrumiyet",
    "value": "1",
    "borcluyaOzel": true,
    "label": "EGM Mahrumiyet"
  },
  {
    "type": "ucretsizSorgu",
    "name": "sskaktif",
    "value": "1",
    "borcluyaOzel": true,
    "label": "SSK (Aktif)"
  },
  {
    "type": "ucretsizSorgu",
    "name": "mernis",
    "value": "1",
    "borcluyaOzel": true,
    "label": "Mernis"
  },
  {
    "type": "ucretsizSorgu",
    "name": "mersis",
    "value": "1",
    "borcluyaOzel": true,
    "label": "Mersis"
  },
  {
    "type": "ucretsizSorgu",
    "name": "safahat",
    "value": "1",
    "borcluyaOzel": false,
    "label": "Safahat"
  },
  {
    "type": "ucretsizSorgu",
    "name": "sgkkamu",
    "value": "1",
    "borcluyaOzel": true,
    "label": "SGK KAMU"
  },
  {
    "type": "ucretsizSorgu",
    "name": "gib",
    "value": "1",
    "borcluyaOzel": true,
    "label": "GİB"
  },
  {
    "type": "ucretsizSorgu",
    "name": "tahred",
    "value": "1",
    "borcluyaOzel": false,
    "label": "Tahsilat/Reddiyat"
  },
  {
    "type": "ucretsizSorgu",
    "name": "tahredkalan",
    "value": "1",
    "borcluyaOzel": false,
    "label": "Tahsilat/Reddiyat Kalan Tutar"
  },
  {
    "type": "ucretsizSorgu",
    "name": "sgkbagkur",
    "value": "1",
    "borcluyaOzel": true,
    "label": "SGK BAĞKUR"
  },
  {
    "type": "ucretsizSorgu",
    "name": "dib",
    "value": "1",
    "borcluyaOzel": true,
    "label": "Dış işleri"
  },
  {
    "type": "ucretsizSorgu",
    "name": "evraklar",
    "value": "1",
    "borcluyaOzel": false,
    "label": "Evraklar"
  },
  {
    "type": "ucretsizSorgu",
    "name": "sgkisyeri",
    "value": "1",
    "borcluyaOzel": true,
    "label": "SGK İŞYERİ"
  },
  {
    "type": "ucretsizSorgu",
    "name": "dosyadetay",
    "value": "1",
    "borcluyaOzel": false,
    "label": "Dosya Bilgileri"
  },
  {
    "type": "ucretliSorgu",
    "name": "takbis",
    "value": "1",
    "borcluyaOzel": true,
    "label": "Takbis"
  },
  {
    "type": "ucretliSorgu",
    "name": "sgkhaciz",
    "value": "1",
    "borcluyaOzel": true,
    "label": "SGK Haciz"
  },
  {
    "type": "ucretsizSorgu",
    "name": "iski",
    "value": "1",
    "borcluyaOzel": true,
    "label": "İSKİ"
  },
  {
    "type": "ucretsizSorgu",
    "name": "dosyahesap",
    "value": "1",
    "borcluyaOzel": false,
    "label": "Dosya Hesabı"
  },
  {
    "type": "ucretliSorgu",
    "name": "icradosyakaydi",
    "value": "1",
    "borcluyaOzel": true,
    "label": "İcra Dosyası Kaydı"
  },
  {
    "type": "ucretsizSorgu",
    "name": "gsm",
    "value": "1",
    "borcluyaOzel": true,
    "label": "GSM"
  },
  {
    "type": "ucretliSorgu",
    "name": "postaceki",
    "value": "1",
    "borcluyaOzel": true,
    "label": "Posta Çeki"
  },
  {
    "type": "ucretsizSorgu",
    "name": "barkod",
    "value": "1",
    "borcluyaOzel": false,
    "label": "Barkod"
  },
  {
    "type": "ucretliSorgu",
    "name": "bankahesap",
    "value": "1",
    "borcluyaOzel": true,
    "label": "Banka Hesapları"
  },
  {
    "type": "ucretsizSorgu",
    "name": "mtstebligat",
    "value": "1",
    "borcluyaOzel": false,
    "label": "Tebligat Durumu (MTS Dosyalarında)"
  },
  {
    "type": "ucretliSorgu",
    "name": "hisse",
    "value": "1",
    "borcluyaOzel": true,
    "label": "Hisse"
  },
  {
    "type": "ucretliSorgu",
    "name": "takyidat",
    "value": "1",
    "borcluyaOzel": true,
    "label": "Takyidat"
  },
  {
    "type": "ucretliSorgu",
    "name": "bes",
    "value": "1",
    "borcluyaOzel": true,
    "label": "BES"
  }];

